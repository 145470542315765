<template>
  <div id="team_form" v-if="teamData">
    <div class="d-flex">
      <h3 class="mb-0 ml-0 color4 font-weight-bolder">
        {{ teamData.id == 0 ? "ADD" : "EDIT" }} CATEGORY
      </h3>
    </div>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row class="mt-2 mt-sm-3">
            <!-- Field : EN Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Category Name (EN)"
                rules="required"
              >
                <b-form-group
                  label="Category Name (EN):"
                  label-for="en_name"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    v-model="teamData.en_name"
                    :state="getValidationState(validationContext)"
                    id="en_name"
                    placeholder="EN Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field : CN Name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Category Name(CN)"
                rules="required"
              >
                <b-form-group
                  label="Category Name(CN):"
                  label-for="cn_name"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    v-model="teamData.cn_name"
                    :state="getValidationState(validationContext)"
                    id="cn_name"
                    placeholder="CN Name"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field : Background Color-->
            <b-col cols="12" md="6">
              <b-form-group
                label="Background Color"
                label-for="background_color"
                label-cols-md="3"
              >
                <b-input-group>
                  <b-form-input
                    id="background_color"
                    v-model="teamData.background_color"
                    autofocus
                    trim
                  />
                  <b-input-group-append is-text>
                    <el-color-picker
                      v-model="teamData.background_color"
                      size="mini"
                    ></el-color-picker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Field : Font Color-->
            <b-col cols="12" md="6">
              <b-form-group
                label="Font Color"
                label-for="font_color"
                label-cols-md="3"
              >
                <b-input-group>
                  <b-form-input
                    id="font_color"
                    v-model="teamData.font_color"
                    autofocus
                    trim
                  />
                  <b-input-group-append is-text>
                    <el-color-picker
                      v-model="teamData.font_color"
                      size="mini"
                    ></el-color-picker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <!-- Label: Category Leader -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="Category Leader"
                rules=""
              >
                <b-form-group
                  label="Category Leader"
                  label-for="team_leader"
                  label-cols-md="3"
                  class=""
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="teamData.leader_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="userOptions"
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Select Leader"
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Admins -->
            <!-- <b-col cols="12" md="6">
              <validation-provider #default="validationContext" name="Admins" rules="required">
                <b-form-group label="Admins" label-for="admins" label-cols-md="3" class="required">
                  <div class="form-col-select">
                    <v-select v-model="teamData.admins" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="adminOptions" :clearable="false" label="name" :reduce="(option) => option.id"
                      placeholder="Select Admin(s)" multiple />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col> -->

            <!-- Label: Category Member -->
            <b-col cols="12" md="6" v-if="false">
              <validation-provider
                #default="validationContext"
                name="Category Participants"
                rules="required"
              >
                <b-form-group
                  label="Category Participants"
                  label-for="team_participants"
                  label-cols-md="3"
                  class="required"
                >
                  <div class="form-col-select">
                    <v-select
                      v-model="teamData.users"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="
                        userOptions.filter((x) => x.id != teamData.leader_id)
                      "
                      :clearable="false"
                      label="name"
                      :reduce="(option) => option.id"
                      placeholder="Select Participants"
                      multiple
                    />
                    <feather-icon size="18" icon="ChevronDownIcon" />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field : Description-->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="Description"
              >
                <b-form-group
                  label="Description"
                  label-for="team_description"
                  label-cols="12"
                  class="required"
                >
                  <b-form-textarea
                    id="team_description"
                    :state="getValidationState(validationContext)"
                    v-model="teamData.description"
                    autofocus
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
          <!-- <b-button variant="primary" @click="handleEdit"> Edit </b-button>
          <b-button :to="{ name: 'teams-list' }"> Cancel </b-button> -->
        </b-form>
      </b-overlay>
    </validation-observer>
  </div>
</template>

<script>
import {
  BOverlay,
  BSidebar,
  BBadge,
  BCardText,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";

export default {
  components: {
    BOverlay,
    BSidebar,
    BBadge,
    BCardText,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    teamData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$router.replace("/teams/list");
    },
    onSubmit() {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Edit",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-team/updateTeam", this.teamData)
            .then((response) => {
              this.loading = true;

              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.$router.replace("/teams/list");
            })
            .catch((error) => {
              this.loading = true;

              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const resetTeamData = () => {
      props.teamData = JSON.parse(JSON.stringify({ id: 0 }));
    };
    const userOptions = ref([]);

    const fetchUserData = () => {
      store
        .dispatch("app-team/fetchUsers")
        .then((response) => {
          userOptions.value = response.data.users;
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          userOptions.value = undefined;
          adminOptions.value = undefined;
          console.log(error);
          // }
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetTeamData);

    onMounted(() => {
      fetchUserData();
    });

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      userOptions,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
</style>
<style lang="scss" scoped>
.badge {
  padding: 10px !important;
  margin: 10px 20px 10px 0px;
}

#edit_button {
  position: absolute;
  right: 80px;
  padding: 5px 10px;
  top: -3px;
}

#cancel_button {
  position: absolute;
  right: 0px;
  padding: 5px 10px;
  top: -3px;
}

.input-group-text {
  padding: 0 1rem;
}

@media only screen and (max-width: 600px) {
  .badge {
    width: 100%;
  }

  #edit_button {
    right: 0px;
  }

  #cancel_button {
    display: none;
  }
}
</style>
