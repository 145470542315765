<template>
  <div>
  <b-card title="Team Leader" body-class="team_body">
        <b-badge class="badge-glow" variant="success" v-for="(data, index) in teamData.users" :key="index" v-if="data.is_teamleader">
        {{data.name}}
      </b-badge>
  </b-card>

  <b-card title="Team Members" body-class="team_body">
        <b-badge class="badge-glow" v-for="(data, index) in teamData.users" :key="index" v-if="!data.is_teamleader">
        {{data.name}}
      </b-badge>
  </b-card>

  </div>
</template>

<script>

import { BBadge, BCardText, BCard } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BCardText,
    BCard
  },
  data() {
    return {

    };
  },
  props: {
    teamData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

.badge{
    padding:10px !important;
    margin:10px 20px 10px 0px;
}
.team_body{
    padding:0 1.5rem;
}


</style>
