<template>
  <component :is="teamData === undefined ? 'div' : 'b-card'">
    <!-- Alert : No item found -->
    <b-alert variant="danger" :show="teamData === undefined">
      <h4 class="alert-heading">Error fetching category data</h4>
      <div class="alert-body">
        No team found with this category id. Check
        <b-link class="alert-link" :to="{ name: 'teams-list' }"> Category List </b-link>
        for other categorys.
      </div>
    </b-alert>

    <b-row class="match-height" v-if="teamData">
      <b-col cols="12">
        <team-edit-tab-information :team-data="teamData" @refetch-data="refetchData" class="pt-75" />
      </b-col>
    </b-row>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BRow, BCol } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import teamStoreModule from "../teamStoreModule";
import TeamEditTeamMembers from "./TeamEditTeamMembers.vue";
import TeamEditTabInformation from "./TeamEditTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    TeamEditTabInformation,
    TeamEditTeamMembers,
  },
  setup() {
    const teamData = ref(null);
    const APP_TEAM_STORE_MODULE_NAME = "app-team";

    const team = {
      id: 0,
      en_name: "",
      cn_name: "",
      description: "",
      background_color: "",
      font_color: "",
      leader_id: "",
      is_active: true,
      users: "",
      // admins: [],
    };

    //Register module
    if (!store.hasModule(APP_TEAM_STORE_MODULE_NAME))
      store.registerModule(APP_TEAM_STORE_MODULE_NAME, teamStoreModule);
    onUnmounted(() => {
      if (store.hasModule(APP_TEAM_STORE_MODULE_NAME))
        store.unregisterModule(APP_TEAM_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      if (router.currentRoute.name == "teams-create") {
        teamData.value = team;
      } else {
        store
          .dispatch("app-team/fetchTeam", { id: router.currentRoute.params.id })
          .then((response) => {
            console.log(response);
            teamData.value = response.data.team;
          })
          .catch((error) => {
            console.log(error);
            teamData.value = undefined;
          });
      }
    };

    refetchData();

    return {
      teamData,
      refetchData,
    };
  },
};
</script>
